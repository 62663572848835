<template>
  <div>
    <v-btn
      class="button-whatsapp"
      elevation="1"
      icon
      @click="redirectWhatsapp"
    >
      <v-icon
        color="#FFFFFF"
        size="28px"
      >
        mdi-whatsapp
      </v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  data() {
    return {
      //VARIABLES
    };
  },
  beforeMount() {},
  methods: {
    redirectWhatsapp: function () {
      if (this.selectLanguage == "sp") {
        window.open(
          "https://wa.me/528125162695?text=Hola%2C%20estoy%20interesado%20en%20comunicarme%20con%20ustedes.",
          "_blank"
        );
      } else {
        window.open(
          "https://wa.me/528125162695?text=hello.%20I%20am%20interested%20in%20communicating%20with%20you.",
          "_blank"
        );
      }
    },
  },
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    },
  },
  watch: {},
};
</script>

<style scoped>
.button-whatsapp {
  background-color: #25d365;
  position: fixed;
  bottom: 50px;
  right: 20px;
  padding: 10px;
  width: 45px;
  height: 45px;
  z-index: 1;
}
</style>